import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"col"},[_c(VRow,[(_vm.criterios.length == 0)?_c(VRow,{staticClass:"criterio",attrs:{"no-gutters":""}},[_vm._v(" No se han generado criterios. ")]):_vm._e()],1),_vm._l((_vm.criteriosLocal),function(criterio){return _c(VRow,{key:criterio._id,staticClass:"criterio"},[_c(VCol,{attrs:{"cols":"3"}},[_c('div',[(_vm.permisoEscritura)?_c('menuCriterioComponent',{on:{"modificarCriterio":function($event){return _vm.modificarCriterio(criterio)},"eliminarCriterio":function($event){return _vm.eliminarCriterio(criterio)}}}):_vm._e(),_vm._v(_vm._s(criterio.nombre)+" ")],1),_c('div',[(criterio.ponderacion >= 0)?_c('div',{staticClass:"text--secondary text-right"},[_c('span',[_vm._v(" "+_vm._s(criterio.ponderacion)+" % - "+_vm._s(criterio.puntos)+" pts ")])]):_c('div',{staticClass:"text--secondary text-right"},[_c(VTooltip,{attrs:{"bottom":"","color":"#848484"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v(" Promedio ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(criterio.puntos)+" pts")])])],1)])]),_vm._l((criterio.indicadores),function(indicador){return _c(VCol,{key:indicador._id,staticClass:"indicador"},[_c('div',{on:{"dblclick":function($event){return _vm.editarIndicador(indicador, true)}}},[(indicador.editar && _vm.permisoEscritura)?_c('div',{on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.ctrlKey){ return null; }return _vm.guardar(indicador)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.editarIndicador(indicador, false)}]}},[_c(VTextarea,{attrs:{"label":"Descripción:","rows":"2","outlined":"","dense":"","hint":"Presione 'ctrl + enter' para guardar o 'esc' para salir.","disabled":_vm.loading},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=$$v},expression:"descripcion"}})],1):_c('div',[(indicador.descripcion)?_c('span',[_vm._v(" "+_vm._s(indicador.descripcion))]):_c('span',[_c('font',{attrs:{"color":"#F000"}},[_c('i',[_vm._v("No se ha capturado descripción.")])])],1)]),_c('div',{staticClass:"text-right"},[_c(VChip,{attrs:{"color":"grey lighten-2","text-color":"black","x-small":""}},[_c('span',[_vm._v(" "+_vm._s(indicador.puntos.toFixed(1))+" pts.")])])],1)])])})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }