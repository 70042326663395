<template>
  <div>
    <v-card
      outlined
      @drop.prevent="elementoAgregado($event)"
      @dragover.prevent="arrastradoSobre = true"
      @dragenter.prevent="arrastradoSobre = true"
      @dragleave.prevent="arrastradoSobre = false"
      :class="{ 'grey lighten-4': arrastradoSobre }"
      :loading="loading"
      :disabled="loading"
    >
      <v-card-text>
        <v-row class="d-flex flex-column" dense align="center" justify="center">
          <v-icon
            class="mt-5"
            size="60"
            :color="arrastradoSobre ? 'primary' : null"
          >
            mdi-cloud-upload
          </v-icon>
          <span>
            <font :color="arrastradoSobre ? 'primary' : null">
              Arrastra aquí tu archivo
            </font>
          </span>
          <span class="caption text--secondary">
            tamaño máximo permitido ({{ tamanoMaximo / 1000 }}kb)
          </span>
          <span class="caption text--secondary">
            Tipos de archivo permitidos ({{ tiposAceptados.join(" ") }})
          </span>

          <v-file-input
            :rules="reglasArchivo"
            filled
            dense
            color="primary"
            prepend-inner-icon="mdi-image-outline"
            :prepend-icon="null"
            :accept="tiposAceptados.join(',')"
            :label="loading ? 'Cargando el archivo...' : 'Buscar un archivo'"
            class="mt-5"
            style="width: 30%"
            @change="archivoSeleccionado($event)"
          ></v-file-input>

          <span v-if="loading"> Subiendo imagen: {{ uploadProgress }}% </span>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { agregarImagenContenido } from "./imagenesContenido.service";

const pesoMaximo = 500 * 1000;

export default {
  props: ["app", "contenido", "idContenido"],

  computed: {
    ...mapGetters(["imageURL"]),
  },

  data: () => ({
    loading: false,
    arrastradoSobre: false,
    tamanoMaximo: pesoMaximo,
    tiposAceptados: [".png", ".jpg", ".jpeg"],
    reglasArchivo: [
      (v) =>
        !v ||
        v.size < pesoMaximo ||
        "El archivo supera el límite de peso permitido",
    ],
    archivo: null,
    uploadProgress: 0,
  }),

  methods: {
    elementoAgregado(e) {
      this.arrastradoSobre = false;
      if (e.dataTransfer.files.length > 1) {
        this.$systemErrorMessage("Solo se admite subir un archivo a la vez.");
      } else if (!this.esArchivoValido(e.dataTransfer.files[0].type)) {
        this.$systemErrorMessage("Selecciona un tipo de archivo válido.");
      } else if (e.dataTransfer.files[0].size > this.tamanoMaximo) {
        this.$systemErrorMessage(
          "El tamaño archivo supera el límite permitido."
        );
      } else {
        //Es válido
        this.archivo = e.dataTransfer.files[0];
        this.subirArchivo();
      }
    },

    esArchivoValido(tipo) {
      let resultado =
        this.tiposAceptados.filter(
          (tipoAceptado) => tipo == `image/${tipoAceptado.replace(".", "")}`
        ).length > 0;
      return resultado;
    },

    archivoSeleccionado(e) {
      if (e && e.size > this.tamanoMaximo) {
        this.$systemErrorMessage(
          "El tamaño archivo supera el límite permitido"
        );
      } else if (e) {
        this.archivo = e;
        this.subirArchivo();
      }
    },

    async subirArchivo() {
      this.loading = true;

      try {
        const formData = new FormData();
        formData.append("image", this.archivo);

        const uploadHandler = {
          onUploadProgress: (progressEvent) =>
            (this.uploadProgress =
              (progressEvent.loaded * 100) / progressEvent.total),
        };

        const serverResponse = await agregarImagenContenido({
          formData,
          uploadHandler,
          tipoContenido: this.contenido,
          idContenido: this.idContenido,
        });
        this.loading = false;
        this.uploadProgress = 0;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else
          this.$emit(
            "imagenAgregada",
            `${this.imageURL}/${serverResponse.fileUrl}`
          );
      } catch (error) {
        this.loading = false;
        if (!error.handdled) this.$appErrorMessage();
      }
    },
  },
};
</script>
