<template>
  <div>
    <v-card outlined>
      <v-card-title>Agregar imagen desde url</v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="esValido" ref="formulario">
          <v-text-field
            v-model="url"
            :rules="reglasURL"
            filled
            dense
            full-width
            label="URL de la imagen"
            placeholder="Ingresa la URL"
            color="primary"
            prepend-inner-icon="mdi-link"
          ></v-text-field>
        </v-form>
        <v-btn
          small
          color="primary"
          :disabled="!esValido"
          :loading="loading"
          @click="agregarImagen"
          >Agregar imagen</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    esValido: true,
    loading: false,
    url: "",
    reglasURL: [
      (v) => !!v || "Ingresa la url",
      (v) =>
        v.match(/\.(jpeg|jpg|png|svg)$/) != null || "Ingresa una url válida",
    ],
  }),

  methods: {
    agregarImagen() {
      this.$emit("imagenAgregada", this.url);
    },
  },
};
</script>
