import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Agregar imagen desde url")]),_c(VCardText,[_c(VForm,{ref:"formulario",attrs:{"lazy-validation":""},model:{value:(_vm.esValido),callback:function ($$v) {_vm.esValido=$$v},expression:"esValido"}},[_c(VTextField,{attrs:{"rules":_vm.reglasURL,"filled":"","dense":"","full-width":"","label":"URL de la imagen","placeholder":"Ingresa la URL","color":"primary","prepend-inner-icon":"mdi-link"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c(VBtn,{attrs:{"small":"","color":"primary","disabled":!_vm.esValido,"loading":_vm.loading},on:{"click":_vm.agregarImagen}},[_vm._v("Agregar imagen")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }