<template>
  <div>
    <div v-if="loading" class="d-flex justify-center">
      <span class="caption text--secondary">Cargando imágenes...</span>
    </div>
    <div v-else-if="imagenes.length < 1" class="d-flex justify-center">
      <span class="caption text--secondary">No se han subido imágenes aún</span>
    </div>
    <v-row v-else class="listado" justify="start" style="width: 100%">
      <v-col
        v-for="(imagen, index) in imagenes"
        :key="imagen"
        cols="6"
        md="4"
        lg="3"
      >
        <v-card
          @mouseover="indexHover = index"
          @mouseenter="indexHover = index"
          @mouseleave="indexHover = null"
          style="cursor: pointer"
          class="image-card"
        >
          <v-fade-transition>
            <div v-if="indexHover === index" class="image-buttons d-flex">
              <v-btn fab color="error" small @click="abrirEliminacion(imagen)"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
            </div>
          </v-fade-transition>
          <v-img
            :src="`${imageURL}/${imagen}`"
            aspect-ratio="1"
            min-width="100%"
            min-height="100"
            max-height="250"
            @click="agregarImagen(imagen)"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>

    <eliminacion-dialog
      v-if="mostrarEliminacionDialog"
      :mostrar="mostrarEliminacionDialog"
      :tipoContenido="contenido"
      :urlImagen="imagenParaEliminar"
      :idContenido="idContenido"
      @cancelar="cerrarEliminacion()"
      @imagenEliminada="imagenEliminada"
    ></eliminacion-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getImagenesContenido } from "./imagenesContenido.service";

export default {
  props: ["contenido", "idContenido"],

  components: {
    "eliminacion-dialog": () =>
      import("./confirmarEliminacionImagenDialog.vue"),
  },

  data: () => ({
    loading: false,
    imagenes: [],
    indexHover: null,
    mostrarEliminacionDialog: false,
    imagenParaEliminar: "",
  }),

  computed: {
    ...mapGetters(["imageURL"]),
  },

  mounted() {
    this.cargarImagenes();
  },

  methods: {
    async cargarImagenes() {
      this.loading = true;

      try {
        const serverResponse = await getImagenesContenido(
          this.contenido,
          this.idContenido
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.imagenes = serverResponse.imagenes;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    agregarImagen(url) {
      this.$emit("imagenAgregada", `${this.imageURL}/${url}`);
    },

    abrirEliminacion(url) {
      this.imagenParaEliminar = url;
      this.mostrarEliminacionDialog = true;
    },

    cerrarEliminacion() {
      this.mostrarEliminacionDialog = false;
      this.imagenParaEliminar = "";
    },

    imagenEliminada(imagenUrl) {
      this.imagenes = this.imagenes.filter((e) => e !== imagenUrl);
      this.cerrarEliminacion();
    },
  },
};
</script>

<style lang="scss" scoped>
.listado {
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
}

.image-card {
  .image-buttons {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
  }
}
</style>
