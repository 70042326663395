import {
  getServerRequest,
  postServerRequest,
  putServerRequest,
  deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";

import { store } from "../../../../../store/store";
const httpURL = store.getters.httpURL;

export const getImagenesContenido = async (tipoContenido, idContenido) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/contenidos/imagen/${tipoContenido}/${idContenido}`;
  return await getServerRequest(url, config);
};

export const eliminarImagenContenido = async ({
  idContenido,
  tipoContenido,
  urlImagen,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/contenidos/imagen/${tipoContenido}/${idContenido}?urlImagen=${urlImagen}`;

  return await deleteServerRequest(url, config);
};

export async function agregarImagenContenido({
  formData,
  uploadHandler,
  tipoContenido,
  idContenido,
}) {
  const token = store.getters.sessionToken;
  const url = `${httpURL}/contenidos/imagen/${tipoContenido}/${idContenido}`;
  const config = {
    headers: {
      token,
      "Content-Type": "multipart/form-data",
    },
    ...uploadHandler,
  };
  return await putServerRequest(url, formData, config);
}
