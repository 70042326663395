<template>
  <v-card flat>
    <v-container>
      <v-skeleton-loader type="paragraph@2"></v-skeleton-loader>
    </v-container>
    <v-divider class="mx-3"></v-divider>
    <v-skeleton-loader type="list-item-two-line@5"></v-skeleton-loader>
  </v-card>
</template>

<script>
export default {
  name: "loadingInstrumentos",
};
</script>