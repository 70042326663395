<template>
  <v-container>
    <v-card tile outlined class="card">
      <v-list-item dense flat class="seccion">
        <v-list-item-action style="margin-right: 5px">
          <menuSeccionRubricaComponent
            v-if="permisoEscritura"
            :idSeccion="seccion._id"
            :agregarCriterioDisponible="agregarCriterioDisponible"
            @agregarNivel="agregarNivel"
            @agregarCriterio="agregarCriterio"
            @modificarSeccion="modificarSeccion"
            @eliminarSeccion="eliminarSeccion"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ seccion.nombre }}</v-list-item-title>
          <v-list-item-subtitle>{{ ponderacion }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="loading"
        type="list-item-two-line@3"
        style="margin: 10px"
      ></v-skeleton-loader>

      <v-container v-else>
        <!-- ROW -> FILA -->
        <!-- COL -> COLUMNA -->
        <v-row no-gutters v-if="nivelesLocales.length == 0"
          ><v-col class="SinNivelLogro"
            >No se han generado niveles de logro.</v-col
          ></v-row
        >

        <!-- Niveles de logro -->
        <!-- no-gutters: remueve los margenes negativos -->

        <v-row v-else no-gutters>
          <v-col class="tituloNivel" cols="3">
            <v-card-subtitle>Niveles de logro</v-card-subtitle>
          </v-col>
          <v-col cols="9">
            <draggable
              v-model="nivelesLocales"
              tag="div"
              ghost-class="ghost"
              handle=".handle"
              @change="cambiarOrden"
              class="row"
              style="margin: 0px"
            >
              <v-col
                v-for="nivel in nivelesLocales"
                :key="nivel._id"
                style="padding: 0px"
                class="nivelLogro"
              >
                <v-toolbar flat color="#edf4fb">
                  <menuNivelLogroComponent
                    v-if="permisoEscritura"
                    @modificarNivelLogro="modificarNivelLogro(nivel)"
                    @eliminarNivelLogro="eliminarNivelLogro(nivel)"
                  />
                  <span style="margin-left: 10px">
                    {{ nivel.nombre }} ({{ nivel.ponderacion }}%)
                  </span>
                  <v-spacer></v-spacer>
                  <v-icon
                    v-if="permisoEscritura"
                    small
                    style="cursor: move"
                    :class="{ handle: !loading }"
                    >mdi-dots-grid</v-icon
                  >
                </v-toolbar>
              </v-col>
            </draggable>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <criteriosRubricaComponent
          :criterios="criterios"
          :permisoEscritura="permisoEscritura"
          @modificarCriterio="modificarCriterio"
          @eliminarCriterio="eliminarCriterio"
          @modificarIndicador="modificarIndicador"
          @statusInstrumento="statusInstrumento"
          @nuevoLog="nuevoLog"
        />
      </v-container>
    </v-card>

    <editarNivelLogroComponent
      v-if="abrirEditorNivel"
      :mostrar="abrirEditorNivel"
      :idRubrica="idRubrica"
      :editarNivel="editarNivel"
      :nivelModificar="nivelModificar"
      :idSeccion="seccion._id"
      @cancelar="cancelarEditarNivel"
      @criteriosActualizados="criteriosActualizados"
      @nivelGuardado="nivelGuardado"
      @nivelActualizado="nivelActualizado"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />

    <editarCriterioComponent
      v-if="abrirEditorCriterio"
      :mostrar="abrirEditorCriterio"
      :idRubrica="idRubrica"
      :idSeccion="seccion._id"
      :ponderacionMaxCriterio="ponderacionMaxCriterio"
      :editarCriterio="editarCriterio"
      :criterioModificar="criterioModificar"
      @cancelar="cancelarEditarCriterio"
      @criteriosActualizados="criteriosActualizados"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />

    <eliminarNivelLogroComponent
      v-if="eliminarNivel.mostrar"
      :mostrar="eliminarNivel.mostrar"
      :nivelLogro="eliminarNivel.nivel"
      @cancelar="(eliminarNivel.mostrar = false), (eliminarNivel.nivel = null)"
      @nivelEliminado="nivelEliminado"
      @criteriosActualizados="criteriosActualizados"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />

    <eliminarCriterioComponent
      v-if="borrarCriterio.mostrar"
      :mostrar="borrarCriterio.mostrar"
      :criterio="borrarCriterio.criterio"
      @cancelar="
        (borrarCriterio.mostrar = false), (borrarCriterio.criterio = null)
      "
      @criteriosActualizados="criteriosActualizados"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

import menuSeccionRubricaComponent from "./menuSeccionRubrica.vue";
import menuNivelLogroComponent from "./nivelesLogro/menuNivelLogro.vue";

import editarNivelLogroComponent from "./nivelesLogro/editarNivelLogro.vue";
import eliminarNivelLogroComponent from "./nivelesLogro/eliminarNivelLogro.vue";

import criteriosRubricaComponent from "./criterios/criteriosRubricas.vue";
import editarCriterioComponent from "./criterios/editarCriterioRubrica.vue";
import eliminarCriterioComponent from "./criterios/eliminarCriterio.vue";

import {
  getCriteriosRubricaService,
  modificarOrdenNivelRubricaService,
} from "../rubrica.service";

export default {
  name: "seccionRubrica",
  props: {
    idRubrica: { type: String, default: "" },
    seccion: { type: Object, default: () => null },
    seccionNiveles: { type: Array, default: () => null },
    criteriosModificados: { type: Array, default: () => null },
    permisoEscritura: { type: Boolean, default: false },
  },
  watch: {
    criteriosModificados(value) {
      for (const criterio of value) {
        const index = this.criterios.findIndex((x) => x._id == criterio._id);
        if (index >= 0) {
          this.criterios[index].puntos = criterio.puntos;
          this.criterios[index].indicadores = criterio.indicadores;
        }
      }
    },
  },
  components: {
    draggable,
    menuSeccionRubricaComponent,
    menuNivelLogroComponent,
    editarNivelLogroComponent,
    eliminarNivelLogroComponent,
    criteriosRubricaComponent,
    editarCriterioComponent,
    eliminarCriterioComponent,
  },
  computed: {
    ...mapGetters(["sessionToken"]),
    ponderacion() {
      let ponderacion = "";
      if (this.seccion.ponderacion >= 0)
        ponderacion = `${this.seccion.ponderacion}%  -  ${this.seccion.puntos} pts`;
      else ponderacion = `Promedio  -  ${this.seccion.puntos} pts`;
      return ponderacion;
    },
    agregarCriterioDisponible() {
      let sumatoria = 0;
      if (this.criterios.length > 0) {
        this.criterios.forEach((criterio) => {
          if (criterio.ponderacion > 0) {
            sumatoria += criterio.ponderacion;
          }
        });
      }
      if (sumatoria < 100) return true;
      return false;
    },
    ponderacionMaxCriterio() {
      let sumatoria = 0;
      this.criterios.forEach((criterio) => {
        sumatoria += criterio.ponderacion;
      });
      return 100 - sumatoria;
    },
  },
  created() {
    this.nivelesLocales = this.seccionNiveles;
    this.cargarCriterios();
  },
  data() {
    return {
      loading: false,
      nivelesLocales: null,
      // Niveles logro
      abrirEditorNivel: false,
      editarNivel: false,
      nivelModificar: null,
      eliminarNivel: {
        mostrar: false,
        nivel: null,
      },

      // Criterios
      criterios: [],
      abrirEditorCriterio: false,
      editarCriterio: false,
      criterioModificar: null,
      borrarCriterio: {
        mostrar: false,
        criterio: null,
      },

      // Indicador
      abrirEditarIndicador: false,
      indicador: null,
    };
  },
  methods: {
    // $emits
    statusInstrumento(data) {
      this.$emit("statusInstrumento", data);
    },
    nuevoLog(nuevoLog) {
      this.$emit("nuevoLog", nuevoLog);
    },
    modificarSeccion() {
      this.$emit("modificarSeccion", this.seccion._id);
    },
    eliminarSeccion() {
      this.$emit("eliminarSeccion", this.seccion._id);
    },
    nivelGuardado(nuevoNivelLogro) {
      this.$emit("nivelGuardado", nuevoNivelLogro);
    },
    nivelActualizado(nivelModificado) {
      this.$emit("nivelActualizado", nivelModificado);
    },
    nivelEliminado(nivelData){
      this.$emit("nivelEliminado", nivelData);
    },
    // Carga de datos
    async cargarCriterios() {
      try {
        this.loading = true;
        const serverResponse = await getCriteriosRubricaService(
          this.idRubrica,
          this.seccion._id
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.criterios = serverResponse.criterios;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    // Components
    agregarNivel() {
      this.editarNivel = false;
      this.abrirEditorNivel = true;
    },
    modificarNivelLogro(nivel) {
      this.nivelModificar = nivel;
      this.editarNivel = true;
      this.abrirEditorNivel = true;
    },
    eliminarNivelLogro(nivel) {
      this.eliminarNivel.nivel = nivel;
      this.eliminarNivel.mostrar = true;
    },
    cancelarEditarNivel() {
      this.abrirEditorNivel = false;
      this.editarNivel = false;
      this.nivelModificar = null;
    },
    agregarCriterio() {
      this.editarCriterio = false;
      this.abrirEditorCriterio = true;
    },
    modificarCriterio(criterio) {
      this.criterioModificar = criterio;
      this.editarCriterio = true;
      this.abrirEditorCriterio = true;
    },
    eliminarCriterio(criterio) {
      this.borrarCriterio.criterio = criterio;
      this.borrarCriterio.mostrar = true;
    },
    cancelarEditarCriterio() {
      this.abrirEditorCriterio = false;
      this.editarCriterio = false;
      this.criterioModificar = null;
    },
    modificarIndicador(indicador) {
      this.abrirEditarIndicador = true;
      this.indicador = indicador;
    },
    // Funciones
    async cambiarOrden(event) {
      this.loading = true;
      try {
        const idNivelLogro = event.moved.element._id;
        const idSeccion = event.moved.element.seccion;
        const data = {
          nuevoIndex: event.moved.newIndex,
        };

        const serverResponse = await modificarOrdenNivelRubricaService(
          data,
          this.idRubrica,
          idSeccion,
          idNivelLogro
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.criterios = serverResponse.criterios;
          const emitData = { seccion: idSeccion, ordenNiveles: this.nivelesLocales };
          this.$emit("cambioOrdenNivel", emitData);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    criteriosActualizados(criterios) {
      this.criterios = criterios;
    },

    indicadorActualizado(indicador) {
      const indexCriterio = this.criterios.findIndex(
        (x) => x._id == indicador.criterio
      );
      const indexIndicador = this.criterios[
        indexCriterio
      ].indicadores.findIndex((x) => x._id == indicador._id);

      this.criterios[indexCriterio].indicadores[indexIndicador].descripcion =
        indicador.descripcion;
    },
    cancelarEditarIndicador() {
      this.abrirEditarIndicador = false;
      this.indicador = null;
    },
  },
};
</script>

<style scoped>
.card {
  transition: all 0.1s ease;
}

.card:hover {
  box-shadow: rgba(14, 84, 196, 0.2) 0px 2px 7px 0px;
}
.seccion {
  font-weight: bold;
  background-color: #f0f8ff;
}
.SinNivelLogro {
  text-align: center;
  background-color: #f0f8ff;
  border: 1px solid #fff;
}

.tituloNivel {
  /* text-align: center; */
  background-color: #e7eef4;
  border: 0.2px solid #fff;
}
.nivelLogro {
  /* text-align: center; */
  background-color: #edf4fb;
  border: 1px solid #fff;
  margin: 0%;
}
.ghost {
  opacity: 0.5;
  background: #e0e0e0;
}
</style>

/
<!--

#f0f8ff - azul bajito
#e7eef4 - azul fuerte

-->
