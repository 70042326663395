<template>
  <v-container class="col">
    <v-row>
      <v-row no-gutters v-if="criterios.length == 0" class="criterio">
        No se han generado criterios.
      </v-row>
    </v-row>
    <v-row
      v-for="criterio in criteriosLocal"
      :key="criterio._id"
      class="criterio"
    >
      <v-col cols="3">
        <div>
          <menuCriterioComponent
            v-if="permisoEscritura"
            @modificarCriterio="modificarCriterio(criterio)"
            @eliminarCriterio="eliminarCriterio(criterio)"
          />{{ criterio.nombre }}
        </div>
        <div>
          <div
            v-if="criterio.ponderacion >= 0"
            class="text--secondary text-right"
          >
            <span>
              {{ criterio.ponderacion }} % - {{ criterio.puntos }} pts
            </span>
          </div>
          <div v-else class="text--secondary text-right">
            <v-tooltip bottom color="#848484">
              <template v-slot:activator="{ on }">
                <span color="primary" dark v-on="on"> Promedio </span>
              </template>
              <span>{{ criterio.puntos }} pts</span>
            </v-tooltip>
          </div>
        </div>
      </v-col>
      <!-- Div -->
      <v-col
        class="indicador"
        v-for="indicador in criterio.indicadores"
        :key="indicador._id"
      >
        <div @dblclick="editarIndicador(indicador, true)">
          <div
            v-if="indicador.editar && permisoEscritura"
            @keyup.ctrl.enter="guardar(indicador)"
            @keyup.exact.esc="editarIndicador(indicador, false)"
          >
            <v-textarea
              label="Descripción:"
              rows="2"
              v-model="descripcion"
              outlined
              dense
              hint="Presione 'ctrl + enter' para guardar o 'esc' para salir."
              :disabled="loading"
            ></v-textarea>
          </div>

          <div v-else>
            <span v-if="indicador.descripcion">
              {{ indicador.descripcion }}</span
            >
            <span v-else
              ><font color="#F000"><i>No se ha capturado descripción.</i></font>
            </span>
          </div>
          <div class="text-right">
            <v-chip color="grey lighten-2" text-color="black" x-small>
              <span> {{ indicador.puntos.toFixed(1) }} pts.</span>
            </v-chip>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>




<script>
import menuCriterioComponent from "./menuCriterio.vue";
import { editarIndicadorRubricaService } from "../../rubrica.service";

export default {
  name: "criteriosRubricas",
  props: {
    criterios: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    menuCriterioComponent,
  },
  data() {
    return {
      loading: false,
      descripcion: null,
      criteriosLocal: [],
    };
  },
  watch: {
    criterios() {
      this.crearCriteriosLocales();
    },
  },
  mounted() {
    this.crearCriteriosLocales();
  },
  methods: {
    modificarCriterio(criterio) {
      this.$emit("modificarCriterio", criterio);
    },
    eliminarCriterio(criterio) {
      this.$emit("eliminarCriterio", criterio);
    },
    modificarIndicador(indicador) {
      this.$emit("modificarIndicador", indicador);
    },
    crearCriteriosLocales() {
      this.criteriosLocal = [...this.criterios];
      this.criteriosLocal.forEach((criterio) => {
        criterio.indicadores = criterio.indicadores.map((x) => {
          return { ...x, editar: false };
        });
      });
    },
    editarIndicador(indicador, valor, indicadorEditado) {
      if(this.permisoEscritura) {
        const indexCriterio = this.criteriosLocal.findIndex((x) => x._id === indicador.criterio);
        const indexIndicador = this.criteriosLocal[indexCriterio].indicadores.findIndex((x) => x._id === indicador._id);

        this.criteriosLocal[indexCriterio].indicadores[indexIndicador].editar = valor;
        this.descripcion = this.criteriosLocal[indexCriterio].indicadores[indexIndicador].descripcion;

        if (indicadorEditado) {
          this.criteriosLocal[indexCriterio].indicadores[indexIndicador].descripcion = indicadorEditado.descripcion;
        }
      }
    },
    async guardar(indicador) {
      try {
        this.loading = true;

        const data = {
          descripcion: this.descripcion,
        };

        const serverResponse = await editarIndicadorRubricaService(
          data,
          indicador.rubrica,
          indicador.seccion,
          indicador.criterio,
          indicador._id
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const indicadorEditado = serverResponse.indicador;
          this.editarIndicador(indicador, false, indicadorEditado);
          this.$emit("statusInstrumento", serverResponse.status);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style scoped>
.criterio {
  justify-content: center;
  background-color: #e7eef4;
  border: 1px solid #fff;
}
.indicador {
  justify-content: center;
  text-align: center;
  background-color: #edf4fb;
  border: 1px solid #fff;
}
</style>




