import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"1080"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"color":"secondary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Imágenes")])],1),_c(VTabs,{attrs:{"background-color":"white","color":"#0d3863","show-arrows":""}},[_c(VTabsSlider,{attrs:{"color":"blue darken-2"}}),_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.id,staticClass:"mx-1"},[_vm._v(" "+_vm._s(tab.label)+" ")])}),_c(VTabItem,[_c(VContainer,[_c('listadoImagenes',{attrs:{"app":_vm.app,"contenido":_vm.contenido,"idContenido":_vm.idContenido},on:{"imagenAgregada":_vm.agregarImagen}})],1)],1),_c(VTabItem,[_c(VContainer,[_c('subirImagen',{attrs:{"app":_vm.app,"contenido":_vm.contenido,"idContenido":_vm.idContenido},on:{"imagenAgregada":_vm.agregarImagen}})],1)],1),_c(VTabItem,[_c(VContainer,[_c('agregarUrl',{on:{"imagenAgregada":_vm.agregarImagen}})],1)],1)],2),_c(VCardActions,[_c(VBtn,{attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Cancelar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }