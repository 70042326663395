<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title v-if="editarNivel"
          >Editar nivel de logro</v-toolbar-title
        >
        <v-toolbar-title v-else>Agregar nivel de logro</v-toolbar-title>
      </v-toolbar>
      <v-container style="margin-top: 15px">
        <v-text-field
          v-model="nombre"
          label="Nombre"
          :disabled="loading"
          :error-messages="errors.nombre"
          outlined
          dense
          @input="validarNombre()"
          @blur="validarNombre()"
        ></v-text-field>
        <v-select
          v-model="ponderacion"
          :items="ponderaciones"
          label="Ponderación:"
          :disabled="loading"
          outlined
          dense
        ></v-select>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{
            'disable-events': errors.nombre.length > 0 || nombre.length === 0,
          }"
          @click="guardarSeccion()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import {
  crearNivelLogroService,
  modificarNivelRubricaService,
} from "../../rubrica.service";

export default {
  name: "editarNivelLogro",
  props: {
    mostrar: { type: Boolean, default: false },
    idRubrica: { type: String, default: "" },
    editarNivel: { type: Boolean, default: false },
    nivelModificar: { type: Object, default: () => null },
    idSeccion: { type: String, default: "" },
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["httpURL", "sessionToken"]),
  },
  validations: {
    nombre: { required },
  },
  data() {
    return {
      loading: false,
      idNivel: null,
      ponderaciones: [],
      nombre: "",
      ponderacion: 0,
      errors: {
        nombre: [],
      },
    };
  },
  created() {
    this.crearPonderaciones();
    if (this.editarNivel) {
      this.idNivel = this.nivelModificar._id;
      this.nombre = this.nivelModificar.nombre;
      this.ponderacion = this.nivelModificar.ponderacion;
    }
  },
  methods: {
    validarNombre() {
      this.$v.nombre.$touch();
      const errors = [];
      if (this.$v.nombre.$dirty) {
        !this.$v.nombre.required && errors.push("Campo requerido.");
      }
      this.errors.nombre = errors;
    },
    async guardarSeccion() {
      try {
        let serverResponse = null;
        this.loading = true;

        const data = {
          nombre: this.nombre,
          ponderacion: this.ponderacion,
        };

        if (this.nivelModificar) {
          serverResponse = await modificarNivelRubricaService(
            data,
            this.idRubrica,
            this.idSeccion,
            this.idNivel
          );
        } else {
          serverResponse = await crearNivelLogroService(
            data,
            this.idRubrica,
            this.idSeccion
          );
        }
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (!this.nivelModificar) {
            this.$emit("nivelGuardado", serverResponse.nuevoNivelLogro);
            this.$emit("statusInstrumento", serverResponse.status);
          } else {
            this.$emit("nivelActualizado", serverResponse.nivelModificado);
          }
          this.$emit("criteriosActualizados", serverResponse.criterios);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async crearPonderaciones() {
      for (let i = 0; i <= 100; i += 5) {
        this.ponderaciones.push({ value: i, text: i + "%" });
      }
    },
  },
};
</script>