import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.regresar}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.rubrica ? _vm.rubrica.nombre : "")+" "),_c(VSpacer),(_vm.permisoEscritura)?_c('menuRubricaComponent',{attrs:{"permisoEliminar":_vm.rubrica.esAdmin},on:{"modificarNombre":function($event){_vm.modificarNombre = true},"eliminarRubrica":_vm.eliminarRubrica}}):_vm._e()],1),(_vm.loading)?_c(VCardText,[_c('loadingRubricaComponent')],1):_c(VCardText,[_c(VListItem,[(!_vm.modificarDescripcion)?_c(VCardText,[(_vm.rubrica.descripcion)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.rubrica.descripcion)}}):_c('span',[_c('i',[_vm._v("No se ha capturado descripción.")])])]):_c('modificarDescripcionComponent',{attrs:{"mostrar":_vm.modificarDescripcion,"descripcionActual":_vm.rubrica.descripcion},on:{"cancelar":function($event){_vm.modificarDescripcion = false},"descripcionModificada":_vm.descripcionModificada,"nuevoLog":_vm.nuevoLog}}),(_vm.modificarDescripcion != true)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.permisoEscritura)?_c('button',_vm._g({staticClass:"title",on:{"click":function($event){_vm.modificarDescripcion = true}}},on),[_c(VIcon,{attrs:{"medium":""}},[_vm._v("mdi-square-edit-outline")])],1):_vm._e()]}}],null,false,4030713281)},[_c('span',[_vm._v("Modificar descripción")])]):_vm._e()],1),_c(VDivider,{staticClass:"mx-3"}),_c('p',{staticClass:"text-center"},[_c('instrumentoStatusComponent',{staticStyle:{"margin":"10px"},attrs:{"status":_vm.rubrica.status}})],1),_c('seccionesRubricaComponent',{attrs:{"permisoEscritura":_vm.permisoEscritura},on:{"statusInstrumento":_vm.statusInstrumento,"nuevoLog":_vm.nuevoLog}}),_c('usuariosRubricaComponent',{attrs:{"permisoEscritura":_vm.permisoEscritura},on:{"nuevoLog":_vm.nuevoLog}}),(_vm.rubrica && _vm.permisoEscritura)?_c('logsRubricaComponent',{attrs:{"logs":_vm.rubrica.logs}}):_vm._e()],1)],1),(_vm.modificarNombre)?_c('modificarNombreComponent',{attrs:{"mostrar":_vm.modificarNombre,"nombre":_vm.rubrica.nombre},on:{"cancelar":function($event){_vm.modificarNombre = false},"nombreModificado":_vm.nombreModificado,"nuevoLog":_vm.nuevoLog}}):_vm._e(),(_vm.eliminar.mostrar)?_c('eliminarRubricaComponent',{attrs:{"mostrar":_vm.eliminar.mostrar,"rubrica":_vm.eliminar.rubrica},on:{"cancelar":_vm.cancelarEliminarRubrica,"rubricaEliminada":_vm.rubricaEliminada}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }