<template>
    <v-dialog v-model="mostrar" persistent  :max-width="500">
        <v-card>
            <v-toolbar dense color="danger" dark flat class="text-h6">Eliminar rubrica</v-toolbar>
            <v-container grid-list-md>
                <p><b>¿Está seguro de eliminar la rubrica?</b> <br>
                <ul><li>{{rubrica.nombre}}</li></ul>
                <i>Esta acción no podrá ser revertida</i> </p>
            </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" 
                        small 
                        outlined 
                        :disabled="loading" 
                        @click="$emit('cancelar')">Cancelar</v-btn>
                        <v-btn color="danger" 
                        small
                        dark
                        :loading="loading" 
                        @click="eliminar()">Eliminar</v-btn>
                    </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { eliminarRubrica } from "./rubrica.service";
export default {
  name: "eliminarRubrica",
  props: {
    mostrar: Boolean,
    rubrica: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async eliminar() {
      try {
        this.loading = true;
        const idRubrica = this.rubrica._id;

        const serverResponse = await eliminarRubrica(
          idRubrica
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("rubricaEliminada");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>