import { store } from '@/store/store';

export const uniqueArrayObjects = (array, key) => {
    return [...new Map(array.map(item => [item[key].toString(), item])).values()]
}

export const getDayByLetter = (letter) => {
    const days = {
        L: "Lunes",
        M: "Martes",
        I: "Miércoles",
        J: "Jueves",
        V: "Viernes",
        S: "Sábado",
        D: "Domingo",
    }

    return days[letter];
}

export const permisoMenuEscuela = (seccion) => {
    const menu = store.getters.menu;
    const app = store.getters.app;

    if(app !== 'escuela') return true;

    const menuEvaluacion = menu.find((x) => x.title === "Evaluación");
    if (menuEvaluacion)
        return (menuEvaluacion.items.findIndex((x) => x.title === seccion) >= 0)
    return false;
}

export const getTypeFormatByName = (name) => {
    // '>>>' Este es un operador de desplazamiento a la derecha, utilizado para asegurarse de que el resultado final sea un número no negativo.
    // En caso de que sea -1 o -2 los trasnformará a un número mayor a la longitud del nombre recibido.
    // Con el slice en caso de que el índice sea mayor que la longitud del nombre de archivo, el resultado será "".
    const lastIndexDot = name.lastIndexOf(".") - 1 >>> 0;
    const format = name.slice(lastIndexDot + 2);
    return format;
}