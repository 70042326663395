<template>
  <v-dialog v-model="mostrar" persistent max-width="400">
    <v-card>
      <v-toolbar dense flat dark color="primary" class="text-h6"
        >{{ titulo }}
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <v-container>
        <v-text-field
          label="Nombre:"
          v-model="busqueda"
          outlined
          dense
          :disabled="loading"
          hint="Presione 'Enter' para buscar"
          :error-messages="busquedaErrors"
          @input="$v.busqueda.$touch()"
          @blur="$v.busqueda.$touch()"
          @keyup.enter="buscarUsuarios()"
        ></v-text-field>
        <v-list v-if="usuariosFiltrados.length > 0" :disabled="loading">
          <v-list-item
            v-for="(usuario, index) in usuariosFiltrados"
            :key="index"
            @click="usuarioSeleccionado(usuario._id)"
          >
            <v-list-item-avatar>
              <v-img
                v-if="usuario.avatar"
                :src="`${imageURL}/avatar/${usuario.avatar}`"
              />
              <v-icon v-else size="45">mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                usuario.nombreCompleto
              }}</v-list-item-title>
              <v-list-item-subtitle v-if="mostrarCorreo">{{
                usuario.correo
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
      <v-card-text v-if="resultados.length == 0 && !loading"
        >No se encontraron resultados.</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          :disabled="loading"
          color="primary"
          @click="$emit('cerrar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { buscarUsuarioService } from "./usuarios.service";

export default {
  name: "buscarUsuario",
  props: {
    loading: Boolean,
    mostrar: Boolean,
    titulo: { type: String, default: "Buscar usuario" },
    excluir: Array,
    banderaExcluir: { type: Boolean, default: false },
    ruta: String,
    mostrarCorreo: { type: Boolean, default: false },
    cerrarAlSeleccionar: { type: Boolean, default: false },
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["imageURL"]),
    busquedaErrors() {
      const errors = [];
      if (!this.$v.busqueda.$dirty) return errors;
      !this.$v.busqueda.minLength && errors.push("Mínimo 5 caracteres.");
      !this.$v.busqueda.required && errors.push("Campo requerido.");
      return errors;
    },
    usuariosFiltrados() {
      if(this.excluir){
        return this.resultados.filter(
          (x) => this.excluir.findIndex((y) => y == x._id) < 0
        );
      }
      return this.resultados;
    },
  },
  validations: {
    busqueda: { required, minLength: minLength(5) },
  },
  data() {
    return {
      busqueda: "",
      resultados: [],
      usuarios: [],
    };
  },
  methods: {
    async buscarUsuarios() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$emit("loadingStart");
          const serverResponse = await buscarUsuarioService(
            this.busqueda,
            this.ruta,
            this.banderaExcluir,
          );
          this.$emit("loadingStop");
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else {
            this.resultados = serverResponse.usuarios;
          }
        }
      } catch (error) {
        this.$emit("loadingStop");
        this.$appErrorMessage();
      }
    },
    usuarioSeleccionado(idUsuario) {
      this.$emit("usuarioAction", idUsuario);
      if (this.cerrarAlSeleccionar) this.$emit("cerrar", idUsuario);
    },
  },
};
</script>
