import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500px","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[(_vm.editarNivel)?_c(VToolbarTitle,[_vm._v("Editar nivel de logro")]):_c(VToolbarTitle,[_vm._v("Agregar nivel de logro")])],1),_c(VContainer,{staticStyle:{"margin-top":"15px"}},[_c(VTextField,{attrs:{"label":"Nombre","disabled":_vm.loading,"error-messages":_vm.errors.nombre,"outlined":"","dense":""},on:{"input":function($event){return _vm.validarNombre()},"blur":function($event){return _vm.validarNombre()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c(VSelect,{attrs:{"items":_vm.ponderaciones,"label":"Ponderación:","disabled":_vm.loading,"outlined":"","dense":""},model:{value:(_vm.ponderacion),callback:function ($$v) {_vm.ponderacion=$$v},expression:"ponderacion"}})],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{class:{
          'disable-events': _vm.errors.nombre.length > 0 || _vm.nombre.length === 0,
        },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.guardarSeccion()}}},[_vm._v("Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }