<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6"
        >Modificar nombre</v-toolbar
      >
      <v-container style="margin-top: 10px">
        <v-text-field
          v-model="nombreLocal"
          label="Nombre"
          :disabled="loading"
          :error-messages="errors.nombre"
          outlined
          dense
          @input="validarNombre()"
          @blur="validarNombre()"
        ></v-text-field>
      </v-container>
      <v-spacer></v-spacer>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{
            'disable-events': errors.nombre.length,
          }"
          @click="actualizarNombre()"
          >Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { actualizarNombreService } from "./rubrica.service";

export default {
  name: "modificarNombre",
  props: {
    mostrar: { type: Boolean, default: false },
    nombre: { type: String, default: "" },
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userId"]),
  },
  validations: {
    nombreLocal: { required },
  },
  data() {
    return {
      loading: false,
      nombreLocal: "",
      errorMessage: null,
      errors: {
        nombre: [],
      },
    };
  },
  created() {
    this.nombreLocal = this.nombre;
  },
  methods: {
    validarNombre() {
      this.$v.nombreLocal.$touch();
      const errors = [];
      if (this.$v.nombreLocal.$dirty) {
        !this.$v.nombreLocal.required && errors.push("Campo requerido.");
      }
      this.errors.nombre = errors;
    },
    async actualizarNombre() {
      try {
        const { idInstrumento } = this.$route.params;
        this.loading = true;
        const data = {
          nombre: this.nombreLocal,
        };

        const serverResponse = await actualizarNombreService(
          data,
          idInstrumento
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("nombreModificado", serverResponse.nuevaRubrica.nombre);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
