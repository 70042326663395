<template>
  <v-dialog v-model="mostrar" persistent max-width="1080">
    <v-card outlined>
      <v-toolbar color="secondary" dark flat dense>
        <v-toolbar-title>Imágenes</v-toolbar-title>
      </v-toolbar>
      <v-tabs background-color="white" color="#0d3863" show-arrows>
        <v-tabs-slider color="blue darken-2"></v-tabs-slider>
        <v-tab v-for="tab in tabs" :key="tab.id" class="mx-1">
          {{ tab.label }}
        </v-tab>

        <v-tab-item>
          <v-container>
            <listadoImagenes
              :app="app"
              :contenido="contenido"
              :idContenido="idContenido"
              @imagenAgregada="agregarImagen"
            ></listadoImagenes>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <subirImagen
              :app="app"
              :contenido="contenido"
              :idContenido="idContenido"
              @imagenAgregada="agregarImagen"
            ></subirImagen>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <agregarUrl @imagenAgregada="agregarImagen"></agregarUrl>
          </v-container>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-btn color="secondary" outlined small @click="cancelar()"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import listadoImagenes from "./administradorImagenes/listadoImagenes.vue";
import subirImagen from "./administradorImagenes/subirImagen.vue";
import agregarUrl from "./administradorImagenes/agregarUrl.vue";

export default {
  props: ["mostrar", "app", "contenido", "idContenido"],

  components: {
    listadoImagenes,
    subirImagen,
    agregarUrl,
  },

  computed: {
    ...mapGetters(["colors"]),
  },

  data: () => ({
    tabs: [
      { label: "Mis imágenes", id: "misArchivos" },
      { label: "Subir", id: "subir" },
      { label: "Agregar desde URL", id: "desdeUrl" },
    ],
  }),

  methods: {
    agregarImagen(url) {
      this.$emit("imagenAgregada", url);
    },

    cancelar() {
      this.$emit("cancelar");
    },
  },
};
</script>
