<template>
      <v-chip v-if="status == 'terminado'" color="green darken-2" label x-small outlined >
        Terminado
      </v-chip>
      <v-chip v-else color="red darken-2" label x-small outlined >
        Incompleto
      </v-chip>
</template>

<script>
export default {
  name: "instrumentoStatus",
  props: {
    status: { type: String }
  },
};
</script>
