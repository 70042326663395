<template>
  <v-dialog v-model="mostrar" persistent max-width="400">
    <v-card>
      <v-toolbar dense flat dark color="danger" class="text-h6"
        >Quitar usuario
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <v-container>¿Está seguro que desea quitar a {{ usuario.nombreCompleto }}?</v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          dark
          color="danger"
          :loading="loading"
          @click="quitarUsuario()"
          >Quitar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { quitarUsuarioService } from "./usuarios.service";

export default {
  name: "quitarUsuarioContenido",
  props: {
    mostrar: Boolean,
    usuario: Object,
    tipo: String,
    ruta: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async quitarUsuario() {
      try {
        this.loading = true;
        const serverResponse = await quitarUsuarioService(this.usuario._id, this.ruta);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            idUsuario:this.usuario._id,
            tipo: this.tipo,
            serverResponse
          }
          this.$emit("usuarioEliminado", emitData);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
