import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":500},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"danger","dark":"","flat":""}},[_vm._v("Eliminar rubrica")]),_c(VContainer,{attrs:{"grid-list-md":""}},[_c('p',[_c('b',[_vm._v("¿Está seguro de eliminar la rubrica?")]),_vm._v(" "),_c('br'),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.rubrica.nombre))])]),_c('i',[_vm._v("Esta acción no podrá ser revertida")])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"danger","small":"","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.eliminar()}}},[_vm._v("Eliminar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }