<template>
    <v-dialog v-model="mostrar" persistent  :max-width="500">
        <v-card>
            <v-toolbar dense color="danger" dark flat class="text-h6">Eliminar criterio</v-toolbar>
            <v-container grid-list-md>
                <p><b>¿Está seguro de eliminar el criterio?</b> <br>
                <ul><li>{{criterio.nombre}}</li></ul>
                <i>Esta acción no podrá ser revertida.</i> </p>
            </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" 
                        small 
                        outlined 
                        :disabled="loading" 
                        @click="$emit('cancelar')">Cancelar</v-btn>
                        <v-btn color="danger" 
                        small
                        dark
                        :loading="loading" 
                        @click="eliminar()">Eliminar</v-btn>
                    </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { eliminarCriterioRubrica } from "../../rubrica.service";

export default {
  name: "eliminarCriterio",
  props: {
    mostrar: Boolean,
    criterio: Object,
  },
  data() {
    return {
      loading: false,
      idRubrica: null,
      idSeccion: null,
      idCriterio: null,
    };
  },
  created() {
    this.idRubrica = this.criterio.rubrica;
    this.idSeccion = this.criterio.seccion;
    this.idCriterio = this.criterio._id;
  },
  methods: {
    async eliminar() {
      try {
        this.loading = true;

        const serverResponse = await eliminarCriterioRubrica(
          this.idRubrica,
          this.idSeccion,
          this.idCriterio
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("criteriosActualizados", serverResponse.criterios);
          this.$emit("statusInstrumento", serverResponse.status);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>