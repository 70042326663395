<template>
  <div>
    <v-card :loading="loading" class="my-2" outlined>
      <v-list subheader>
        <v-subheader>
          {{ titulo }}
          <v-spacer></v-spacer>
          <v-tooltip v-if="permisoEscritura && !vistaSeguimiento" top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="$emit('agregarUsuario')"
                color="primary"
                v-on="on"
                icon
                x-small
                ><v-icon>mdi-account-plus</v-icon></v-btn
              >
            </template>
            <span>Agregar usuario</span>
          </v-tooltip>
        </v-subheader>
        <v-divider class="mx-1"></v-divider>

        <v-list-item v-if="usuarios.length == 0">
          <v-list-item-content>
            <v-list-item-subtitle
              >No se han asignado usuarios</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <template v-for="(usuario, index) in usuarios">
          <v-divider v-if="index > 0" :key="index" class="mx-2"></v-divider>
          <v-list-item :key="usuario._id">
            <v-list-item-avatar>
              <img
                v-if="usuario.avatar"
                :src="`${imageURL}/avatar/${usuario.avatar}`"
              />
              <v-icon v-else size="45">mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                usuario.nombreCompleto
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ usuario.correo }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="permisoEscritura">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="$emit('quitarUsuario', { usuario, tipo })"
                    v-on="on"
                    icon
                    x-small
                    color="red darken-1"
                    ><v-icon>mdi-close-circle</v-icon></v-btn
                  >
                </template>
                <span>Quitar usuario</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "usuariosContenido",
  props: {
    titulo: { type: String, default: "Usuarios" },
    tipo: { type: String, default: "usuarios" },
    usuarios: Array,
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: { ...mapGetters(["imageURL", "vistaSeguimiento"]) },

  data() {
    return {
      loading: false,
    };
  },
  methods: {},
};
</script>
