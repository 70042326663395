<template>
  <div>
    <v-card flat>
      <v-card-text class="pa-0">
        <ckeditor
          :editor="editor"
          v-model="editorData"
          :config="editorConfig"
          @input="textoModificado"
        ></ckeditor>
      </v-card-text>
    </v-card>
    <administrador-imagenes
      v-if="mostrarAdministrador"
      :mostrar="mostrarAdministrador"
      :contenido="contenido"
      :idContenido="idContenido"
      @imagenAgregada="insertarImagen"
      @cancelar="cancelar"
    ></administrador-imagenes>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ClassicEditor from "./ckeditor/ckeditor";
import CKEditor from "@ckeditor/ckeditor5-vue";

import administradorImagenes from "./administradorImagenes.vue";

export default {
  props: {
    texto: {
      required: false,
      type: String,
      default: "<p></p>",
    },
    contenido: {
      required: true,
      validator: function(v) {
        return (
          [
            "encuesta",
            "examen",
            "cursoCampus",
            "cursoPlantilla",
            "listaCotejo",
            "rubrica",
          ].indexOf(v) !== -1
        );
      },
    },
    idContenido: {
      required: true,
      type: String,
    },
  },

  components: {
    ckeditor: CKEditor.component,
    "administrador-imagenes": administradorImagenes,
  },

  computed: {
    ...mapGetters(["colors"]),
  },

  data: () => ({
    editor: ClassicEditor,
    value: null,
    editorData: "<p></p>",
    editorConfig: {
      toolbar: {
        shouldNotGroupWhenFull: true,
      },
    },
    mostrarAdministrador: false,
  }),

  watch: {
    editorData() {
      if (
        this.editorData.includes('<figure class="image"><img src=""></figure>')
      ) {
        this.mostrarAdministrador = true;
      }
    },

    texto() {
      setTimeout(() => {
        this.editorData = this.texto;
      }, 100);
    },
  },

  created() {
    this.editorData = this.texto ? this.texto : this.editorData;
  },

  methods: {
    obtenerTexto() {
      return this.editorData;
    },

    insertarImagen(url) {
      this.mostrarAdministrador = false;
      this.editorData = this.editorData.split('src=""').join(`src="${url}"`);
    },

    cancelar() {
      this.mostrarAdministrador = false;
      this.editorData = this.editorData
        .split('<figure class="image"><img src=""></figure>')
        .join("");
    },

    resetearTexto() {
      this.editorData = "<p></p>";
    },

    textoModificado() {
      this.$emit("textoModificado", this.editorData);
    },
  },
};
</script>

<style lang="scss">
.ck-editor__editable_inline {
  min-height: 100px;
}
</style>
