<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark icon small v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="$emit('modificarSeccion', idSeccion)">
        <v-list-item-title>Modificar sección</v-list-item-title>
        <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item @click="$emit('agregarNivel', idSeccion)">
        <v-list-item-title>Agregar nivel de logro</v-list-item-title>
        <v-list-item-icon
          ><v-icon small>mdi-table-row-plus-after</v-icon></v-list-item-icon
        >
      </v-list-item>
      <v-list-item
        :disabled="!agregarCriterioDisponible"
        @click="$emit('agregarCriterio', idSeccion)"
      >
        <v-list-item-title>Agregar criterio</v-list-item-title>
        <v-list-item-icon
          ><v-icon small>mdi-table-column-plus-after</v-icon></v-list-item-icon
        >
      </v-list-item>
      <v-list-item @click="$emit('eliminarSeccion', idSeccion)">
        <v-list-item-title>
          <font color="#D32F2F"> Eliminar sección </font>
        </v-list-item-title>
        <v-list-item-icon
          ><v-icon color="red darken-2" small
            >mdi-trash-can-outline</v-icon
          ></v-list-item-icon
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "menuSeccion",
  props: ["idSeccion", "agregarCriterioDisponible"],
  data() {
    return {
      loading: true,
    };
  },
};
</script>