<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else rounded="lg">
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-btn icon @click="regresar"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-spacer></v-spacer> {{ rubrica ? rubrica.nombre : "" }}
        <v-spacer></v-spacer>
        <menuRubricaComponent
          v-if="permisoEscritura"
          :permisoEliminar="rubrica.esAdmin"
          @modificarNombre="modificarNombre = true"
          @eliminarRubrica="eliminarRubrica"
        />
      </v-toolbar>

      <v-card-text v-if="loading">
        <loadingRubricaComponent />
      </v-card-text>

      <v-card-text v-else>
        <v-list-item>
          <v-card-text v-if="!modificarDescripcion">
            <div 
              v-if="rubrica.descripcion"
              v-html="rubrica.descripcion"
            >
            </div>
            <span v-else>
              <i>No se ha capturado descripción.</i>
            </span>
          </v-card-text>
          <modificarDescripcionComponent
            v-else
            :mostrar="modificarDescripcion"
            :descripcionActual="rubrica.descripcion"
            @cancelar="modificarDescripcion = false"
            @descripcionModificada="descripcionModificada"
            @nuevoLog="nuevoLog"
          />
          <v-tooltip top v-if="modificarDescripcion != true">
            <template v-slot:activator="{ on }">
              <button
                v-if="permisoEscritura"
                v-on="on"
                class="title"
                @click="modificarDescripcion = true"
              >
                <v-icon medium>mdi-square-edit-outline</v-icon>
              </button>
            </template>
            <span>Modificar descripción</span>
          </v-tooltip>
        </v-list-item>

        <v-divider class="mx-3"></v-divider>
        <p class="text-center">
          <instrumentoStatusComponent
            :status="rubrica.status"
            style="margin: 10px"
          />
        </p>

        <seccionesRubricaComponent
          :permisoEscritura="permisoEscritura"
          @statusInstrumento="statusInstrumento"
          @nuevoLog="nuevoLog"
        />
        <usuariosRubricaComponent
          :permisoEscritura="permisoEscritura"
          @nuevoLog="nuevoLog"
        ></usuariosRubricaComponent>
        <logsRubricaComponent
          v-if="rubrica && permisoEscritura"
          :logs="rubrica.logs"
        ></logsRubricaComponent>
      </v-card-text>
    </v-card>

    <modificarNombreComponent
      v-if="modificarNombre"
      :mostrar="modificarNombre"
      :nombre="rubrica.nombre"
      @cancelar="modificarNombre = false"
      @nombreModificado="nombreModificado"
      @nuevoLog="nuevoLog"
    />

    <eliminarRubricaComponent
      v-if="eliminar.mostrar"
      :mostrar="eliminar.mostrar"
      :rubrica="eliminar.rubrica"
      @cancelar="cancelarEliminarRubrica"
      @rubricaEliminada="rubricaEliminada"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import menuRubricaComponent from "./menuRubrica.vue";
import loadingRubricaComponent from "./loadingRubrica.vue";
import instrumentoStatusComponent from "../instrumentoStatus.vue";
import seccionesRubricaComponent from "./secciones/seccionesRubrica.vue";

import modificarNombreComponent from "./modificarNombreRubrica.vue";
import modificarDescripcionComponent from "./modificarDescripcionRubrica.vue";
import eliminarRubricaComponent from "./eliminarRubrica.vue";
import usuariosRubricaComponent from "./usuariosRubrica.vue";
import logsRubricaComponent from "../../widgets/logs.vue";

import { getRubricaService } from "./rubrica.service";
import { permisoMenuEscuela } from '../../../helpers/utilerias';

export default {
  name: "rubricaView",
  props: {},
  components: {
    menuRubricaComponent,
    loadingRubricaComponent,
    instrumentoStatusComponent,
    modificarNombreComponent,
    modificarDescripcionComponent,
    eliminarRubricaComponent,
    seccionesRubricaComponent,
    usuariosRubricaComponent,
    logsRubricaComponent,
  },
  computed: {
    ...mapGetters(["colors", "sessionToken", "menu"]),
  },
  watch: {
    sessionToken(value) {
      if (value) {
        if (!permisoMenuEscuela("Instrumentos")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getRubrica();
        }
      }
    },
  },
  mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Instrumentos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.getRubrica();
      }
    }
  },
  data() {
    return {
      permisoEscritura: false,
      loading: true,
      rubrica: null,
      modificarNombre: false,
      modificarDescripcion: false,
      eliminar: {
        mostrar: false,
        rubrica: null,
      },
      escuelaTienePermiso: false,
    };
  },
  methods: {
    async getRubrica() {
      try {
        this.loading = true;
        let idInstrumento = this.$route.params.idInstrumento;
        const serverResponse = await getRubricaService(idInstrumento);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/instrumentos#rubricas");
        } else {
          this.rubrica = serverResponse.rubrica;
          this.permisoEscritura = serverResponse.permisoEscritura;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    regresar() {
      if (!this.rubrica.esAdmin) {
        this.$router.push("/instrumentos#compartidos");
      }
      this.$router.push("/instrumentos#rubricas");
    },
    eliminarRubrica() {
      this.eliminar.mostrar = true;
      this.eliminar.rubrica = this.rubrica;
    },
    cancelarEliminarRubrica() {
      this.eliminar.mostrar = false;
      this.eliminar.rubrica = null;
    },
    rubricaEliminada() {
      this.$router.push("/instrumentos#rubricas");
    },
    nombreModificado(nombre) {
      this.modificarNombre = false;
      this.rubrica.nombre = nombre;
    },
    descripcionModificada(descripcion) {
      this.modificarDescripcion = false;
      this.rubrica.descripcion = descripcion;
    },
    statusInstrumento(data) {
      this.rubrica.status = data.status;
    },
    nuevoLog(nuevoLog) {
      this.rubrica.logs.unshift(nuevoLog);
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>
