<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark icon x-small v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="$emit('modificarCriterio')">
        <v-list-item-title>Modificar criterio</v-list-item-title>
        <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item @click="$emit('eliminarCriterio')">
        <v-list-item-title>
          <font color="#D32F2F"> Eliminar criterio </font>
        </v-list-item-title>
        <v-list-item-icon
          ><v-icon color="red darken-2" small
            >mdi-trash-can-outline</v-icon
          ></v-list-item-icon
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "menuCriterio",
  data() {
    return {
      loading: true,
    };
  },
  methods: {},
};
</script>