<template>
  <v-container slot="content">
    <v-row dense>
      <v-col cols="12">
        <editor-texto
          ref="editorTexto"
          :texto="descripcionActual"
          contenido="rubrica"
          :idContenido="$route.params.idInstrumento"
        ></editor-texto>
      </v-col>

      <v-col cols="12">
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="$emit('cancelar')"
            >Cancelar</v-btn
          >

          <v-btn
            color="primary"
            dark
            small
            :loading="loading"
            @click="actualizarDescripcion()"
            >Modificar</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import editorTexto from "../../contenidos/editorTexto.vue";

import { modificarDescripcionService } from "./rubrica.service";
export default {
  props: {
    mostrar: { type: Boolean, default: false },
    descripcionActual: { type: String, default: "" },
  },
  components: {
    "editor-texto": editorTexto,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async actualizarDescripcion() {
      try {
        const { idInstrumento } = this.$route.params;
        this.loading = true;
        const data = {
          descripcion: this.$refs.editorTexto.obtenerTexto(),
        };

        const serverResponse = await modificarDescripcionService(
          data,
          idInstrumento
        );

        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit(
            "descripcionModificada",
            serverResponse.nuevaRubrica.descripcion
          );
          this.$emit("nuevoLog", serverResponse.nuevoLog);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>