<template>
      <v-card outlined class="my-2">
        <v-subheader class="font-weight-bold">{{titulo}}</v-subheader>
        <v-divider class="mx-3"></v-divider>
        <v-skeleton-loader type="list-item-avatar-two-line@3"></v-skeleton-loader>
      </v-card>
</template>

<script>

export default {
  name: "usuariosLoading",
  props: {
    titulo: {type: String, default: 'Usuarios'}
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
