import { patchServerRequest, getServerRequest, putServerRequest, deleteServerRequest } from "../../../services/serverRequest.service";
import { store } from '../../../../../store/store';

const httpURL = store.getters.httpURL;

export const getRubricaService = async (idRubrica) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}`;
    return await getServerRequest(url, config);
};

export const actualizarNombreService = async (data, idRubrica) => {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/instrumento/rubrica/nombre/${idRubrica}`;
    const config = { headers: { token: sessionToken } };
    return await patchServerRequest(url, data, config);
};

export const modificarDescripcionService = async (data, idRubrica) => {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/instrumento/rubrica/descripcion/${idRubrica}`;
    const config = { headers: { token: sessionToken } };
    return await patchServerRequest(url, data, config);
}

export const eliminarRubrica = async (idRubrica) => {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}`;
    const config = { headers: { token: sessionToken } };
    return await deleteServerRequest(url, config);
}

// Secciones
export const getSeccionesRubricaService = async (idRubrica) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/secciones`;
    return await getServerRequest(url, config);
}

export const crearSeccionRubricaService = async (data, idRubrica) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/seccion`;
    return await putServerRequest(url, data, config);
}

export const editarSeccionRubricaService = async (data, idRubrica, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/seccion/${idSeccion}`;
    return await patchServerRequest(url, data, config);
}

export const eliminarSeccionRubrica = async (idRubrica, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/seccion/${idSeccion}`;
    return await deleteServerRequest(url, config);
}

// Niveles logro
export const crearNivelLogroService = async (data, idRubrica, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/nivel/${idSeccion}`;
    return await putServerRequest(url, data, config);
}

export const modificarNivelRubricaService = async (data, idRubrica, idSeccion, idNivelLogro) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/nivel/${idSeccion}/${idNivelLogro}`;
    return await patchServerRequest(url, data, config);
}

export const modificarOrdenNivelRubricaService = async (data, idRubrica, idSeccion, idNivelLogro) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/nivel/${idSeccion}/${idNivelLogro}/orden`;
    return await patchServerRequest(url, data, config);
}

export const eliminarNivelLogroRubrica = async (idRubrica, idSeccion, idNivelLogro) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/nivel/${idSeccion}/${idNivelLogro}`;
    return await deleteServerRequest(url, config);
}

// Criterios
export const getCriteriosRubricaService = async (idRubrica, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/criterios/${idSeccion}`;
    return await getServerRequest(url, config);
}

export const crearCriterioRubricaService = async (data, idRubrica, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/criterio/${idSeccion}`;
    return await putServerRequest(url, data, config);
}

export const editarCriterioRubricaService = async (data, idRubrica, idSeccion, idCriterio) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/criterio/${idSeccion}/${idCriterio}`;
    return await patchServerRequest(url, data, config);
}

export const eliminarCriterioRubrica = async (idRubrica, idSeccion, idCriterio) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/criterio/${idSeccion}/${idCriterio}`;
    return await deleteServerRequest(url, config);
}

// Indicadores
export const editarIndicadorRubricaService = async (data, idRubrica, idSeccion, idCriterio, idIndicador) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/${idRubrica}/criterio/${idSeccion}/${idCriterio}/${idIndicador}`;
    return await patchServerRequest(url, data, config);
}

// colaboradores y compartido
export async function obtenerUsuariosRubricaService(tipo, idRubrica) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/rubrica/usuarios/${tipo}/${idRubrica}`;
    return await getServerRequest(url, config);
}

export async function agregarUsuarioRubricaService(tipo, idRubrica, idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const data = { idUsuario };
    const url = `${httpURL}/instrumento/rubrica/usuarios/${tipo}/${idRubrica}`;
    return await putServerRequest(url, data, config);
}