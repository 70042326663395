<template>
  <v-container>
    <v-card shaped outlined>
      <v-list-item>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="permisoEscritura"
              v-on="on"
              class="title"
              small
              icon
              color="primary"
              :disabled="loading || !agregarSeccionDisponible"
              @click="mostrarAgregarSeccion()"
            >
              <v-icon>mdi-text-box-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Agregar sección</span>
        </v-tooltip>
      </v-list-item>

      <v-skeleton-loader
        v-if="loading"
        type="list-item-two-line@3"
        style="margin: 10px"
      ></v-skeleton-loader>

      <template v-else>
        <v-card
          shaped
          outlined
          style="margin: 10px"
          v-if="secciones.length === 0"
        >
          <v-toolbar dense flat class="text-bold">
            <span class="text--secondary"
              >No se han agregado secciones a esta rubrica.</span
            >
          </v-toolbar>
        </v-card>

        <seccionRubricaComponent
          v-for="seccion in secciones"
          :key="seccion._id"
          :idRubrica="idRubrica"
          :seccion="seccion"
          :seccionNiveles="seccion.nivelesLogro"
          :criteriosModificados="criteriosModificados"
          :permisoEscritura="permisoEscritura"
          @modificarSeccion="modificarSeccion"
          @eliminarSeccion="quitarSeccion"
          @nivelGuardado="nivelGuardado"
          @nivelActualizado="nivelActualizado"
          @nivelEliminado="nivelEliminado"
          @cambioOrdenNivel="cambioOrdenNivel"
          @statusInstrumento="statusInstrumento"
          @nuevoLog="nuevoLog"
        />
      </template>
    </v-card>

    <editarSeccionComponent
      v-if="abrirEditorSeccion"
      :mostrar="abrirEditorSeccion"
      :idRubrica="idRubrica"
      :ponderacionMax="ponderacionMax"
      :editarSeccion="editarSeccion"
      @cancelar="(abrirEditorSeccion = false), (editarSeccion = null)"
      @seccionGuardada="seccionGuardada"
      @seccionActualizada="seccionActualizada"
      @actualizarPuntosSecciones="actualizarPuntosSecciones"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />

    <eliminarSeccionComponent
      v-if="eliminarSeccion.mostrar"
      :mostrar="eliminarSeccion.mostrar"
      :idRubrica="idRubrica"
      :seccion="eliminarSeccion.seccion"
      @cancelar="cancelarEliminarSeccion"
      @seccionEliminada="seccionEliminada"
      @actualizarPuntosSecciones="actualizarPuntosSecciones"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import editarSeccionComponent from "./editarSeccionRubrica.vue";
import seccionRubricaComponent from "./seccionRubrica.vue";
import eliminarSeccionComponent from "./eliminarSeccionRubrica.vue";

import { getSeccionesRubricaService } from "../rubrica.service";

export default {
  name: "seccionesRubrica",
  props: {
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    editarSeccionComponent,
    seccionRubricaComponent,
    eliminarSeccionComponent,
  },
  computed: {
    ...mapGetters(["sessionToken"]),
    agregarSeccionDisponible() {
      let sumatoria = 0;
      if (this.secciones.length > 0) {
        this.secciones.forEach((seccion) => {
          if (seccion.ponderacion > 0) {
            sumatoria += seccion.ponderacion;
          }
        });
      }
      return sumatoria < 100;
    },
  },
  watch: {
    sessionToken() {
      this.cargarSecciones();
    },
  },
  created() {
    this.idRubrica = this.$route.params.idInstrumento;
  },
  mounted() {
    if (this.sessionToken) this.cargarSecciones();
  },
  data() {
    return {
      loading: false,
      idRubrica: null,
      secciones: [],
      // Secciones
      abrirEditorSeccion: false,
      editarSeccion: null,
      ponderacionMax: 100,
      eliminarSeccion: {
        mostrar: false,
        seccion: null,
      },
      // Criterios
      criteriosModificados: null,
    };
  },
  methods: {
    // $emits
    statusInstrumento(data) {
      this.$emit("statusInstrumento", data);
    },
    nuevoLog(nuevoLog) {
      this.$emit("nuevoLog", nuevoLog);
    },
    // Carga de datos
    async cargarSecciones() {
      try {
        this.loading = true;
        const serverResponse = await getSeccionesRubricaService(this.idRubrica);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.secciones = serverResponse.secciones;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    //Components
    mostrarAgregarSeccion() {
      let sumatoria = 0;
      if (this.secciones.length > 0) {
        this.secciones.forEach((seccion) => {
          if (seccion.ponderacion > 0) {
            sumatoria += seccion.ponderacion;
          }
        });
      }
      this.ponderacionMax = 100 - sumatoria;
      this.abrirEditorSeccion = true;
    },
    modificarSeccion(idSeccion) {
      this.editarSeccion = {};
      let sumatoria = 0;
      this.secciones.forEach((seccion) => {
        if (seccion._id != idSeccion) {
          if (seccion.ponderacion > 0) sumatoria += seccion.ponderacion;
        } else this.editarSeccion = seccion;
      });
      this.ponderacionMax = 100 - sumatoria;
      this.abrirEditorSeccion = true;
    },
    quitarSeccion(idSeccion) {
      this.eliminarSeccion.seccion = this.secciones.find(
        (x) => x._id == idSeccion
      );
      this.eliminarSeccion.mostrar = true;
    },
    cancelarEliminarSeccion() {
      this.eliminarSeccion.mostrar = false;
      this.eliminarSeccion.seccion = null;
    },
    //Funciones
    seccionGuardada(nuevaSeccion) {
      this.secciones.push(nuevaSeccion);
    },
    seccionActualizada(seccionModificada) {
      const indexSeccion = this.secciones.findIndex((x) => x._id == seccionModificada._id);
      if (indexSeccion >= 0) {
        this.secciones[indexSeccion].nombre = seccionModificada.nombre;
        this.secciones[indexSeccion].ponderacion = seccionModificada.ponderacion;
      }
    },
    seccionEliminada(idSeccion) {
      const index = this.secciones.findIndex((x) => x._id == idSeccion);
      if (index >= 0) this.secciones.splice(index, 1);
    },
    actualizarPuntosSecciones(data) {
      for (const seccion of data.seccionesActualizadas) {
        const index = this.secciones.findIndex((x) => x._id == seccion._id);
        if (index >= 0) this.secciones[index].puntos = seccion.puntos;
      }
      this.criteriosModificados = data.criteriosActualizados;
    },
    nivelGuardado(nuevoNivel) {
      const indexSeccion = this.secciones.findIndex((seccion) => seccion._id == nuevoNivel.seccion);
      this.secciones[indexSeccion].nivelesLogro.push(nuevoNivel);
    },
    nivelActualizado(nivelModificado) {
      const indexSeccion = this.secciones.findIndex((seccion) => seccion._id == nivelModificado.seccion);
      const indexNivel = this.secciones[indexSeccion].nivelesLogro.findIndex((nivel) => nivel._id == nivelModificado._id);

      this.secciones[indexSeccion].nivelesLogro[indexNivel].nombre = nivelModificado.nombre;
      this.secciones[indexSeccion].nivelesLogro[indexNivel].ponderacion = nivelModificado.ponderacion;
    },
    nivelEliminado(nivelData){
      const indexSeccion = this.secciones.findIndex((seccion) => seccion._id == nivelData.idSeccion);
      const indexNivel = this.secciones[indexSeccion].nivelesLogro.findIndex((nivel) => nivel._id == nivelData.idNivel);

      this.secciones[indexSeccion].nivelesLogro.splice(indexNivel, 1);
    },
    cambioOrdenNivel(nivelesData){
      const indexSeccion = this.secciones.findIndex((seccion) => seccion._id == nivelesData.seccion);
      this.secciones[indexSeccion].nivelesLogro = nivelesData.ordenNiveles;
    },
  },
};
</script>