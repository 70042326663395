import { getServerRequest, putServerRequest, postServerRequest, patchServerRequest, deleteServerRequest } from '@/components/globals/services/serverRequest.service';

import { store } from '../../../../../store/store';

const httpURL = store.getters.httpURL;

export const buscarUsuarioService = async(busqueda, ruta, banderaExcluir) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/${ruta}`;
    return await postServerRequest(url, { busqueda, banderaExcluir }, config);
}

export const quitarUsuarioService = async(idUsuario, ruta) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }, data: { idUsuario } };
    const url = `${httpURL}/${ruta}`;
    return await deleteServerRequest(url, config);
}